@import '../../styling/color'
@import '../../styling/rwd'

.locale-button
	display: flex
	align-items: center
	border: solid 1px $white
	border-radius: 30px
	padding: 4px 12px
	font-size: 12px
	color: $white
	min-width: 99px

	> div
		margin-left: 8px
