@import '../../styling/rwd'

.ter-topic
	display: flex
	padding: 0 15px
	color: #fff

	&__content
		margin-left: 4px

	&__title
		font-size: 21px
		margin-bottom: 5px

	&__sub-title
		font-size: 14px
		color: #e7e6e6
		white-space: nowrap

	+rwd-mobile()
		&__title
			font-size: 16px

		&__sub-title
			font-size: 11px

		svg
			width: 36px
			height: 36px
