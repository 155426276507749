@import '../rwd'
@import '../font'
@import '../color'

html, body
	font-size: 16px
	font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text", "SF Pro Display", system-ui, "Segoe UI", Roboto, "Noto Sans", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"

h1, h2, h3, h4, h5, h6, p
	margin: 0
	font-weight: unset

// .layout

.ant-select
	width: 100%

	.ant-select-arrow
		font-size: 16px
		height: 16px
		color: $black

.ant-select-dropdown
	background-color: $gray

.copy-svg
	cursor: pointer

.ant-modal-root .ant-modal-mask
	background-color: rgba(0, 0, 0, 0.2)

	+rwd-mobile()
		background-color: rgba(0, 0, 0, 0.4)

.ter-client-modal
	.ant-modal-content
		padding: 19px
		border-radius: 8px
		-webkit-backdrop-filter: blur(10px)
		backdrop-filter: blur(10px)
		box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25)
		border: solid 1px $brown
		background-color: rgba(0, 0, 0, 0.8)

	.ant-modal-header
		height: 32px
		margin: 0
		border: unset
		background: unset
		background-image: linear-gradient(to right, rgba(249, 5, 9, 0) 0%, #f90509 50%, rgba(249, 5, 9, 0) 100%)

		.ant-modal-title
			text-align: center
			font-family: YouSheBiaoTiHei
			font-size: 20px
			font-weight: normal
			letter-spacing: -0.4px
			color: $white

	.ant-modal-footer
		display: none

	.ant-modal-close-x
		color: $white
