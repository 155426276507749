.nav-list
	width: 100%

	&__link
		display: flex
		min-height: 70px
		color: #fff
		padding: 0 16px
		cursor: pointer
		text-align: center
		text-decoration: none
		align-items: center

		&:hover
			-webkit-box-shadow: inset 0px -22px 54px -48px rgba(199,117,117,1);
			-moz-box-shadow: inset 0px -22px 54px -48px rgba(199,117,117,1);
			box-shadow: inset 0px -22px 54px -48px rgba(199,117,117,1);

	ul
		list-style: none    
		display: flex
		padding: 0
		margin: 0
		justify-content: space-between
		align-items: center

	.active
		font-weight: 500
