@import '../../../styling/rwd'
@import '../../../styling/color'
@import '../../../styling/params'

.layout-header
	display: flex
	width: 100vw
	overflow: hidden
	height: 70px
	color: $white
	background-color: rgba(0, 0, 0, 0.9)
	padding: "0 calc((100vw - %s) / 2)" % ($desktop-width)
	position: fixed
	left: 0
	right: 0
	z-index: 100
	justify-content: space-between

	@media screen and (min-width: 1610px )
		padding: "0 calc((100vw - %s) / 2)" % ($max-width)

	+rwd-tablet()
		padding: "0 calc((100vw - %s) / 2)" % ($tablet-width)

	+rwd-mobile()
		height: 50px
		padding: 0 16px 0 4px

	&__title,
	&__footer
		display: flex
		align-items: center

	&__content
		display: flex
		width: $desktop-width
		max-width: $max-width
		height: 100%
		padding: 0 78.5px
		margin: auto
		align-items: center
		justify-content: space-between

		+rwd-tablet()
			width: $tablet-width
			padding: 0 22.5px

		+rwd-mobile()
			width: $mobile-width
