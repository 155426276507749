@import '../../styling/rwd'
@import '../../styling/color'

.hamburger
	display: none

	&__icon
		cursor: pointer

	&__mask
		display: none
		position: fixed
		top: 0
		left: 0
		width: 100vw
		height: 100vh
		z-index: 9998
		background-color: #00000066

	&__sidebar
		position: fixed
		transition: all 0.6s
		right: -100vw
		top: 0
		left: auto
		width: 50vw
		min-width: 180px
		height: 100vh
		background-color: rgba(0, 0, 0, 0.9)
		z-index: 9999

		&__menu
			display: flex
			flex-direction: column
			align-items: center

			.ter-topic
				margin: 16px 0

			.nav-list
				text-align: center

				ul
					flex-direction: column
					
					> li
						margin-bottom: 27px

						&:first-child
							margin-top: 27px

				&__link
					min-height: unset

			.locale-button
				margin-top: 16px
				max-width: 99px

	.open
		display: block

	.show
		right: 0

	+rwd-mobile()
		display: block
