// RWD breakpoint
$sm = 767px
$md = 1024px

rwd-mobile()
	@media screen and (max-width: $sm)
		{block}

rwd-tablet()
	@media screen and (min-width: $sm+1 ) and (max-width: $md)
		{block}

rwd-desktop()
	@media screen and (min-width: $md+1 )
		{block}
