@import '../../../styling/rwd'
@import '../../../styling/color'

.layout-footer
	height: 56px
	line-height: 56px
	font-size: 20px
	color: $white
	text-align: center
	background-color: rgba(0, 0, 0, 0.8)

	+rwd-mobile()
		font-size: 16px
