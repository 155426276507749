@import '../../styling/color'

.service-modal
	&__desc
		font-size: 16px
		font-weight: 500
		line-height: 19px
		margin: 28px 0 0 4px
		color: $white

	&__icon
		text-align: center
		margin-top: 40px

	&__link
		font-size: 16px
		font-weight: 600
		line-height: 22px
		text-align: center
		color: $white
		margin: 2px 0 24px

		> a
			color: $white

	&__btn
		display: flex
		justify-content: center
		padding: 16px 0 24px

		button
			display: flex
			height: 40px
			padding: 0 15px
			border-radius: 4px
			border: solid 1px #c92215
			background-color: #c92215
			align-items: center
			font-size: 16px
			font-weight: 500
			color: #fcfaed
			cursor: pointer

			> div
				min-width: 102px
